import { FunctionComponent, h } from 'preact'
import { useEffect, useState } from 'preact/hooks'

import steps from '@utils/stepsPreerdigung'
import { ContentPage } from '@components/screen/styles'
import { MobileFooter } from '@components/screen/mobileFooter'
import { usePreerdigungSetup } from '@components/context/setupProviderPreerdigung'

export type PaymentType = 'one-time' | 'monthly' | null

const PaymentType: FunctionComponent = () => {
  const { setStep, paymentType, setPaymentType } = usePreerdigungSetup()

  return (
    <ContentPage>
      <h1>Zahlungsmodalität für die MEINE ERDE-Vorsorge</h1>

      <ul className="c-product-comparison_list">
        <li
          className="c-product-comparison_item"
          style={{
            '--bg': 'var(--pearl-grey)',
            '--bgDec': 'var(--ivory-white-dec)',
            '--secondary': 'var(--ivory-white)',
            '--text': 'var(--black-humus)',
            '--accent': 'var(--orange-bronze)'
          }}>
          <div className="c-product-comparison_item-content">
            <p className="c-product-comparison_item-heading">Einmalzahlung</p>
            <div
              className="c-product-comparison_item-info-wrapper"
              style={{ backgroundColor: 'var(--pale-pearl-grey)' }}>
              <span className="c-product-comparison_item-upper c-rich-text -base-18">
                <ul>
                  <li>
                    <p>Reerdigung im Wert von 2.800 €</p>
                  </li>
                  <li>
                    <p>Aufnahme in den MEINE ERDE-Freundeskreis</p>
                  </li>
                </ul>
              </span>
              <span className="c-product-comparison_item-lower c-rich-text -base-18">
                <p>
                  <strong>einmalig:</strong> 2.900 €<br />
                  <strong>monatlich:</strong> -
                  <br />
                  <strong>davon:</strong>
                </p>
                <ul>
                  <li>
                    <p>Administration Treuhandkonto: 100 €</p>
                  </li>
                </ul>
              </span>
            </div>
          </div>
          <div className="c-product-comparison_item-bottom">
            <span className="c-rich-text -base-20">
              <p>Insgesamt</p>
              <div className="-larger-paragraph">2.900 €*</div>
            </span>
            <button
              href="/?paymentType=one-time"
              className="c-product-comparison_button"
              style={{
                '--bg': 'var(--cta-orange)',
                '--bgDec': 'var(--cta-orange-hover-dec)',
                '--secondary': 'var(--cta-orange-hover)',
                '--text': 'var(--white)',
                '--accent': 'var(--cta-orange)'
              }}
              onClick={() => {
                setPaymentType('one-time' as PaymentType)
                setStep(steps[1])
              }}>
              Vertragsentwurf anfordern
            </button>
          </div>
        </li>
        <li
          className="c-product-comparison_item"
          style={{
            '--bg': 'var(--bone-white)',
            '--bgDec': 'var(--ivory-white-dec)',
            '--secondary': 'var(--ivory-white)',
            '--text': 'var(--black-humus)',
            '--accent': 'var(--orange-bronze)'
          }}>
          <div className="c-product-comparison_item-content">
            <p className="c-product-comparison_item-heading">Ratenzahlung</p>
            <div
              className="c-product-comparison_item-info-wrapper"
              style={{ backgroundColor: 'var(--pale-pearl-grey)' }}>
              <span className="c-product-comparison_item-upper c-rich-text -base-18">
                <ul>
                  <li>
                    <p>Reerdigung im Wert von 2.800 €</p>
                  </li>
                  <li>
                    <p>Aufnahme in den MEINE ERDE-Freundeskreis</p>
                  </li>
                </ul>
              </span>
              <span className="c-product-comparison_item-lower c-rich-text -base-18">
                <p>
                  <strong>einmalig:</strong> 297 €
                  <br />
                  <strong>monatlich:</strong> 54 Raten à 49,50 €<br />
                  <strong>davon:</strong>
                </p>
                <ul>
                  <li>
                    <p>Administration Treuhandkonto: 100 €</p>
                  </li>
                  <li>
                    <p>Kosten Ratenzahlung: 70 €</p>
                  </li>
                </ul>
              </span>
            </div>
          </div>
          <div className="c-product-comparison_item-bottom">
            <span className="c-rich-text -base-20">
              <p>insgesamt</p>
              <div className="-larger-paragraph">2.970 €*</div>
            </span>
            <button
              href="/?paymentType=monthly"
              className="c-product-comparison_button"
              style={{
                '--bg': 'var(--cta-green)',
                '--bgDec': 'var(--cta-green-hover-dec)',
                '--secondary': 'var(--cta-green-hover)',
                '--text': 'var(--white)',
                '--accent': 'var(--cta-green)'
              }}
              onClick={() => {
                setPaymentType('monthly' as PaymentType)
                setStep(steps[1])
              }}>
              Vertragsentwurf anfordern
            </button>
          </div>
        </li>
      </ul>
      <div class="c-product-comparison_footer">* inklusive MwSt.</div>
      <MobileFooter />
    </ContentPage>
  )
}

export default PaymentType
