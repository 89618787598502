import { h, FunctionComponent } from 'preact'
import Router from 'preact-router'

import GlobalStyles, { Content, Wrapper } from '@components/globalStyles'
import BasicFlowSidebar from '@components/sidebar/basicFlowSidebar'
import { SetupProvider, useSetup } from '@components/context/setupProvider'
import {
  PreerdigungSetupProvider,
  usePreerdigungSetup
} from '@components/context/setupProviderPreerdigung'
import BareboneSidebar from '@components/sidebar/bareboneSidebar'

import PersonalData from '@routesBasic/personalData'
import ServiceSearch from '@routesBasic/serviceSearch'
import Summary from '@routesBasic/summary'
import Confirmation from '@routesBasic/confirmation'
import ValidatePayment from '@routesBasic/validatePayment'

import PersonalDataPreerdigung from '@routesPreerdigung/personalData'
import PaymentTypePreerdigung from '@routesPreerdigung/paymentType'
import SummaryPreerdigung from '@routesPreerdigung/summary'
import ConfirmationPreerdigung from '@routesPreerdigung/confirmation'

import Page404 from '@routes/page404'
import { useEffect } from 'preact/hooks'
import PreerdigungSidebar from './sidebar/preerdigungSidebar'

const SetupRouter: FunctionComponent = () => {
  const { currentStep } = useSetup()

  switch (currentStep?.identifier) {
    case 'data':
      return <PersonalData />
    case 'service':
      return <ServiceSearch />
    case 'summary':
      return <Summary />
    case 'payment':
      return <ValidatePayment />
    case 'confirmation':
      return <Confirmation />
    default:
      return null
  }
}

const SetupPreerdigungRouter: FunctionComponent = () => {
  const { currentStep } = usePreerdigungSetup()

  switch (currentStep?.identifier) {
    case 'data':
      return <PersonalDataPreerdigung />
    case 'payment-type':
      return <PaymentTypePreerdigung />
    case 'summary':
      return <SummaryPreerdigung />
    case 'confirmation':
      return <ConfirmationPreerdigung />
    default:
      return null
  }
}

const BasicFlow: FunctionComponent = () => (
  <SetupProvider>
    <Wrapper>
      <BasicFlowSidebar />
      <Content>
        <SetupRouter />
      </Content>
    </Wrapper>
  </SetupProvider>
)

const PreerdigungFlow: FunctionComponent = () => (
  <PreerdigungSetupProvider>
    <Wrapper>
      <PreerdigungSidebar />
      <Content>
        <SetupPreerdigungRouter />
      </Content>
    </Wrapper>
  </PreerdigungSetupProvider>
)

const Page404Flow: FunctionComponent = () => (
  <Wrapper>
    <BareboneSidebar />
    <Content center={true}>
      <Page404 />
    </Content>
  </Wrapper>
)

const App = () => {
  let url
  if (location.hostname.includes('vorsorge') && location.pathname !== '/vorsorge') {
    // ugly, but needed as otherwise the router is not recognizing the /vorsorge
    // there might be better solutions, so react on history change without having the proper /vorsorge URL
    location.replace('https://vorsorge.meine-erde.de/vorsorge' + window.location.search)
  }

  if (location.pathname === '/sterbegeldversicherung') {
    location.replace('https://meine-erde.de')
  }

  useEffect(() => {
    if (typeof window !== 'undefined' && window.document.cookie) {
      window.document.cookie = window.document.cookie || ''
      let consent: string | undefined = ''
      const value = `; ${window.document.cookie}`
      const parts = value.split(`; gtag_consent=`)
      if (parts.length === 2) {
        consent = parts.pop()?.split(';').shift()
      }
      console.debug('consent', consent)

      if (consent === 'true') {
        gtag('consent', 'default', {
          ad_storage: 'granted',
          analytics_storage: 'granted'
        })
      } else {
        gtag('consent', 'default', {
          ad_storage: 'denied',
          analytics_storage: 'denied'
        })
      }
    }
  }, [])

  return (
    <div id="app">
      <GlobalStyles />
      <Router url={url}>
        <BasicFlow path="/" />
        <PreerdigungFlow path="/vorsorge" />
        <Page404Flow default />
      </Router>
    </div>
  )
}

export default App
