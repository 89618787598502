import { FunctionComponent, h } from 'preact'
import { ContinueButtonCircular as ContinueButtonCircularComponent } from './styles'

type ButtonProps = {
  active?: boolean
  onClick?: () => void
  href?: string
  id?: string
}

const SvgIcon: FunctionComponent = () => (
  <svg width="55" height="44" viewBox="0 0 55 44" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M32 2L52 22L32 42" stroke-width="4" />
    <path d="M52 22L0 22" stroke-width="4" />
  </svg>
)

const ContinueButtonCircular: FunctionComponent<ButtonProps> = ({
  active = false,
  onClick,
  href,
  ...props
}) => (
  <ContinueButtonCircularComponent
    as={href ? 'a' : 'button'}
    href={href}
    tabIndex={href ? (active ? 0 : -1) : 0}
    active={active}
    onClick={onClick}
    target="_blank"
    rel="nofollow noopener noreferrer"
    {...props}>
    <SvgIcon />
  </ContinueButtonCircularComponent>
)

export default ContinueButtonCircular
