import { FunctionComponent, h } from 'preact'
import { Field, Formik, FormikProps } from 'formik'

import {
  ChangeDataField,
  CheckboxContainer,
  ContentPage,
  DataColumn,
  DataRow,
  InputForm,
  InputSpacer,
  PayButton
} from '@components/screen/styles'
import { useSetup } from '@components/context/setupProvider'
import { getSalutation, getBirthday } from '@utils/personalDataUtils'
import { Undertaker } from './serviceSearch'
import { FormikValues as PersonalData } from '@routesBasic/personalData'
import { iso2ToName } from '@utils/countries'
import { LoadingSpinner } from '@components/screen/loadingSpinner'
import { MobileFooter } from '@components/screen/mobileFooter'
import steps from '@utils/steps'
import { getCookie } from '@utils/cookies'

type FormikValues = {
  newsletter: boolean
  terms: boolean
  referral: boolean
}

const Summary: FunctionComponent = () => {
  const {
    orderId,
    setStep,
    setOrderId,
    personalData,
    undertaker,
    pendingPayment,
    setPersonalDataErrors,
    setUndertakerErrors
  } = useSetup()

  if (personalData == null) return <></>

  return (
    <ContentPage>
      <Formik
        initialValues={{ newsletter: false, referral: false, terms: pendingPayment ? true : false }}
        validate={(values) => {
          const errors: { [key: string]: any } = {}

          if (!values.terms) errors.terms = true

          return errors
        }}
        validateOnBlur={false}
        validateOnMount={true}
        onSubmit={async (values, { setSubmitting }) => {
          setSubmitting(true)

          const ga_id = getCookie('_ga')

          const combinedClient: PersonalData & FormikValues & { ga_id: string | undefined } = {
            ...personalData,
            ga_id,
            ...values
          }

          let postData: {
            client: PersonalData & FormikValues
            undertaker?: Undertaker
            undertakerId?: string
            clientId?: string
          } = {
            client: combinedClient
          }

          if (typeof undertaker !== 'string' && undertaker != null) postData.undertaker = undertaker

          try {
            const result = await fetch(
              `${process.env.APIHOST}/order/${pendingPayment ? `${orderId}/edit` : 'new'}`,
              {
                method: 'POST',
                headers: {
                  'Content-Type': 'application/json'
                },
                body: JSON.stringify(postData)
              }
            )

            const data: any = await result.json()

            if (data.error) {
              let targetIndex = 2

              if (Object.keys(data.error).length > 0) {
                const firstKey = Object.keys(data.error)[0]

                switch (firstKey) {
                  case 'salutation':
                  case 'firstName':
                  case 'lastName':
                  case 'phone':
                  case 'birthday':
                  case 'street':
                  case 'postalCode':
                  case 'city':
                  case 'country':
                  case 'email':
                    targetIndex = 0
                    setPersonalDataErrors(data.error)
                    break
                  case 'hubspotId':
                  case 'name':
                  case 'postalCode':
                  case 'city':
                    targetIndex = 1
                    setUndertakerErrors(data.error)
                    break
                }

                setStep(steps[targetIndex])
              }

              return
            }

            if (!data.checkoutUrl) return

            window.dataLayer.push({ event: 'freundeskreisConverted', value: 25 })

            setOrderId(data.id)
            location.href = data.checkoutUrl
          } catch (e) {
            console.error(e)
            setSubmitting(false)
          }
        }}>
        {({ errors, touched, isSubmitting, handleSubmit }: FormikProps<FormikValues>) => (
          <>
            <h1>Alles auf einen Blick</h1>
            <DataRow>
              <DataColumn>
                <b>Kontaktdaten</b>
                <p>
                  {`${getSalutation(personalData.salutation)} ${personalData.firstName} ${
                    personalData.lastName
                  }`}
                  <br />
                  {personalData.email}
                  <br />
                  {personalData.phone}
                  <br />
                  Geburtsdatum: {getBirthday(personalData.birthday)}
                </p>
                <ChangeDataField onClick={() => setStep(steps[0])}>Daten ändern</ChangeDataField>
              </DataColumn>
              <DataColumn hideTitleMobile={true}>
                <b>Adresse</b>
                <p>
                  {personalData.street}
                  <br />
                  {personalData.postalCode} {personalData.city}
                  <br />
                  {iso2ToName(personalData.country)}
                </p>
              </DataColumn>
            </DataRow>
            <DataColumn>
              <b>Gewählter Bestatter</b>
              {typeof undertaker !== 'string' && undertaker != null ? (
                <p>
                  {undertaker.name}
                  <br />
                  {undertaker.hubspotId && (
                    <small>
                      <span>{undertaker.distance?.toFixed(1)} km</span>
                      <span>
                        <svg
                          width="14"
                          height="18"
                          viewBox="0 0 14 18"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg">
                          <path
                            fill-rule="evenodd"
                            clip-rule="evenodd"
                            d="M7 18C7 18 14 13.0909 14 7.36364C14 5.41068 13.2625 3.53771 11.9497 2.15676C10.637 0.775809 8.85652 0 7 0C5.14348 0 3.36301 0.775809 2.05025 2.15676C0.737498 3.53771 0 5.41068 0 7.36364C0 13.0909 7 18 7 18ZM10 7C10 8.65685 8.65685 10 7 10C5.34315 10 4 8.65685 4 7C4 5.34315 5.34315 4 7 4C8.65685 4 10 5.34315 10 7Z"
                            fill="#B9B28C"
                          />
                        </svg>
                        {undertaker.hubspotId && `${undertaker.street}, `}
                        {undertaker.postalCode} {undertaker.city}
                      </span>
                    </small>
                  )}
                </p>
              ) : (
                <p>-</p>
              )}
              <ChangeDataField onClick={() => setStep(steps[1])}>
                Partnerinstitut ändern
              </ChangeDataField>
              {typeof undertaker !== 'string' &&
                undertaker != null &&
                personalData.referralsource.includes('funeral-home') && (
                  <CheckboxContainer>
                    <ul>
                      <Field disabled={isSubmitting} name="referral" type="checkbox" />
                      <li />
                    </ul>
                    <span>
                      Ich habe mich bereits mit dem ausgewählten Bestatter zum Thema Reerdigung
                      unterhalten.
                    </span>
                  </CheckboxContainer>
                )}
            </DataColumn>
            <DataColumn>
              <b>Ihre Bestellung</b>
              <table>
                <tbody>
                  <tr>
                    <td>Mitgliedschaft für den MEINE ERDE Freundeskreis</td>
                    <td>25,00 EUR</td>
                  </tr>
                </tbody>
                <tfoot>
                  <tr>
                    <td>
                      Gesamt <span>(inkl. 3,99 EUR MwSt.)</span>
                    </td>
                    <td>25,00 EUR</td>
                  </tr>
                </tfoot>
              </table>
            </DataColumn>
            <InputSpacer />
            <InputForm onSubmit={handleSubmit}>
              <CheckboxContainer>
                <ul>
                  <Field disabled={isSubmitting} name="newsletter" type="checkbox" />
                  <li />
                </ul>
                <span>
                  Ich möchte Neuigkeiten rund um MEINE ERDE in Form eines Newsletters erhalten.
                </span>
              </CheckboxContainer>
              <CheckboxContainer error={typeof errors.terms !== 'undefined' && touched.terms}>
                <ul>
                  <Field
                    disabled={isSubmitting}
                    name="terms"
                    type="checkbox"
                    checked={pendingPayment}
                  />
                  <li />
                </ul>
                <span>
                  Ich stimme den{' '}
                  <a href="https://www.meine-erde.de/agb" target="_blank">
                    Nutzungsbedingungen
                  </a>{' '}
                  und der{' '}
                  <a href="https://www.meine-erde.de/datenschutz" target="_blank">
                    Datenschutzerklärung
                  </a>{' '}
                  zu.
                </span>
              </CheckboxContainer>
              <PayButton type="submit" disabled={isSubmitting}>
                {isSubmitting ? (
                  <>
                    <LoadingSpinner />
                    Bestellung wird geladen...
                  </>
                ) : (
                  'Zahlungspflichtig bestellen'
                )}
              </PayButton>
            </InputForm>
          </>
        )}
      </Formik>
      <MobileFooter />
    </ContentPage>
  )
}

export default Summary
