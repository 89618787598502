import styled, { keyframes } from 'styled-components'
import { mediaBreakpoints } from '@components/globalStyles'

export const LoadingSpinnerRotator = keyframes`
	100% {
		-webkit-transform: rotate(360deg);
		transform: rotate(360deg);
	}
`

export const LoadingSpinnerDash = keyframes`
	0% {
		stroke-dasharray: 1, 200;
		stroke-dashoffset: 0;
	}
	50% {
		stroke-dasharray: 89, 200;
		stroke-dashoffset: -35;
	}
	100% {
		stroke-dasharray: 89, 200;
		stroke-dashoffset: -124;
	}
`

export const LoadingSpinner = styled.svg`
  animation: ${LoadingSpinnerRotator} 1.5s linear infinite;
  height: 100%;
  width: 100%;
`

export const LoadingSpinnerCirlce = styled.circle<{
  strokeColor?: string
}>`
  stroke-dasharray: 1, 200;
  stroke-dashoffset: 0;
  animation: ${LoadingSpinnerDash} 1.5s ease-in-out infinite 0s;
  stroke-linecap: round;
  fill: none;
  stroke-width: 5;
  stroke: ${(props) => props.strokeColor || 'white'};
`

export const LoadingSpinnerContainer = styled.div<{
  size?: number
}>`
  width: ${(props) => props.size ?? 20}px;
  height: ${(props) => props.size ?? 20}px;
  position: relative;
  display: grid;
  place-items: center;

  ${LoadingSpinner} {
    display: block;
    position: absolute;
  }
`

export const ContentPage = styled.div`
  padding: 56px 50px;
  color: var(--black);
  max-width: 700px;

  h1 {
    margin-bottom: 32px;
    font-size: 32px;
    line-height: 44px;
  }

  p {
    font-size: 18px;
    line-height: 28px;

    b {
      display: block;
      font-size: 20px;
      line-height: 20px;
      margin-bottom: 16px;
      font-weight: 500;
    }

    strong {
      font-weight: 500;
    }
  }

  @media (max-width: ${mediaBreakpoints.mobile}) {
    margin: 0 auto;
    padding: 24px 16px;

    h1 {
      margin-bottom: 16px;
      font-size: 24px;
      line-height: 32px;
    }

    p {
      font-size: 16px;
      line-height: 24px;
    }
  }
`

export const MobileFooterContainer = styled.div`
  display: none;
  text-align: center;
  color: var(--sage);
  font-size: 12px;
  line-height: 16px;
  max-width: 240px;
  margin: 80px auto 48px;

  @media (max-width: ${mediaBreakpoints.mobile}) {
    display: block;
  }

  a {
    text-decoration: underline;
    text-underline-offset: 1px;
  }
`

export const InputForm = styled.form`
  margin-top: 20px;

  @media (max-width: ${mediaBreakpoints.mobile}) {
    margin-top: 16px;
  }
`

export const ContentPageWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;

  ${ContentPage} {
    display: flex;
    flex-direction: column;
    flex: 1;
    padding-bottom: 208px;

    ${InputForm} {
      flex: 1;
    }
  }

  @media (max-width: ${mediaBreakpoints.mobile}) {
    ${ContentPage} {
      padding-bottom: 112px;
    }
  }
`

export const SearchIcon = styled.svg`
  stroke: var(--sage);
  width: 24px;
  height: 24px;
`

export const InputDatePlaceholder = styled.input`
  position: absolute;
  top: 0;
  height: 56px;
  font-size: 18px;
  line-height: 20px;
  display: flex;
  align-items: center;
  pointer-events: none;
  color: var(--black) !important;
  border-bottom-color: transparent !important;
`

export const InputError = styled.span`
  display: block;
  color: var(--form-error);
  font-weight: 500;
  font-size: 18px;
  line-height: 28px;
  margin-top: 16px;
`

export const InputItem = styled.div<{
  minWidth?: string
  maxWidth?: string
  isDatePicker?: boolean
  error?: boolean
  hideContent?: boolean
}>`
  width: 100%;
  ${(props) => props.maxWidth && `max-width: ${props.maxWidth};`}
  position: relative;

  input,
  select {
    ${(props) => props.isDatePicker && `min-height: 56px;`}
    width: 100%;
    font-size: 18px;
    line-height: 20px;
    background: none;
    border: none;
    border-bottom: solid 2px ${(props) => (props.error ? 'var(--form-error)' : 'var(--sage)')};
    color: ${(props) => (props.error ? 'var(--form-error)' : 'var(--black)')};
    padding: 16px 0;
    text-indent: 0;
    border-radius: 0;
    ${(props) => props.hideContent && `color: transparent;`}
    transition: border-bottom-color 0.2s ease;

    ${(props) =>
      props.error &&
      `
            &::placeholder {
                color: var(--form-error);
            }

            &:-ms-input-placeholder {
                color: var(--form-error);
            }

            &::-ms-input-placeholder {
                color: var(--form-error);
            }
        `}

    &:focus {
      border-bottom-color: var(--form-focus);
    }

    &[type='date'] {
      &::-webkit-inner-spin-button,
      &::-webkit-calendar-picker-indicator {
        display: none;
        -webkit-appearance: none;
      }

      &::-webkit-date-and-time-value {
        text-align: left;
      }
    }

    &:focus {
      outline: none;
    }

    &::placeholder {
      color: ${(props) => (props.error ? 'var(--form-error)' : 'var(--black)')};
      opacity: 0.7;
    }
  }

  select {
    cursor: pointer;
    appearance: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    background-image: url(/assets/caret.svg);
    background-position: center right 2px;
    background-repeat: no-repeat;
  }

  & + & {
    margin-top: 8px;
  }

  ${SearchIcon} {
    position: absolute;
    right: 5px;
    top: 50%;
    margin-top: -15px;
  }

  ${LoadingSpinnerContainer} {
    position: absolute;
    right: 5px;
    top: 50%;
    margin-top: -15px;
  }

  @media (max-width: ${mediaBreakpoints.mobile2}) {
    ${(props) => props.minWidth && `max-width: ${props.minWidth};`}
  }
`

export const InputRow = styled.div<{
  columnBreakpoint?: string
}>`
  display: flex;
  flex-wrap: wrap;

  ${InputItem} {
    flex: 1;

    & + ${InputItem} {
      margin-top: 0;
      margin-left: 24px;
    }
  }

  & + &,
  & + ${InputItem}, ${InputItem} + & {
    margin-top: 8px;
  }

  ${InputError} {
    min-width: 100%;
  }

  @media (max-width: ${mediaBreakpoints.mobile2}) {
    ${InputItem} + ${InputItem} {
      margin-left: 16px;
    }
  }

  @media (max-width: ${(props) => props.columnBreakpoint ?? mediaBreakpoints.mobile2}) {
    flex-direction: column;

    ${InputItem} + ${InputItem} {
      margin-left: 0;
      margin-top: 8px;
    }
  }
`

export const InputSpacer = styled.div`
  height: 58px;
`

export const ContinueButtonCircular = styled.button<{
  active?: boolean
}>`
  position: fixed;
  display: grid;
  place-items: center;
  bottom: 32px;
  right: 56px;
  width: 96px;
  height: 96px;
  border-radius: 48px;
  z-index: 5;
  outline: 0;
  transition: background-color 0.2s ease, color 0.2s ease,
    transform 0.5s cubic-bezier(0.86, 0.07, 0.07, 0.96);
  cursor: var(${(props) => (props.active ? 'pointer' : 'default')});

  a {
    width: 100%;
    height: 100%;
    display: grid;
    place-items: center;
  }

  svg {
    stroke: var(${(props) => (props.active ? '--orange-bronze' : '--sage')});
  }

  &:hover,
  &:focus-visible {
    ${(props) => props.active && 'transform: translateX(12px);'}
  }

  @media (max-width: ${mediaBreakpoints.mobile}) {
    width: 72px;
    height: 72px;
    right: 16px;
    bottom: 18px;

    svg {
      width: 41px;
      height: 32px;
    }
  }
`

export const SearchResult = styled.label`
  display: flex;
  align-items: center;
  padding: 20px 1px 20px 0;
  cursor: pointer;

  div {
    flex: 1;
    color: var(--black);

    b {
      font-weight: 500;
      display: block;
      font-size: 20px;
      line-height: 20px;
      margin-bottom: 12px;
    }

    small {
      font-size: 16px;
      line-height: 16px;
      font-weight: 400;
      display: flex;
      align-items: center;

      svg {
        margin-left: 11px;
        margin-right: 7px;
        height: 18px;
      }
    }
  }

  ul {
    width: 20px;
    height: 20px;
    display: grid;
    place-items: center;
    position: relative;

    li {
      position: absolute;
      content: 'a';
      width: 20px;
      height: 20px;
      border-radius: 10px;
      border: solid 2px var(--border);
      background: var(--background);
      transition: border 0.2s ease, background 0.2s ease;
    }

    input:checked + li {
      border: solid 2px var(--orange-bronze);
      background: var(--orange-bronze);
    }
  }

  &:hover ul input:not(:checked) + li {
    background: var(--border);
  }

  & + & {
    border-top: solid 1px var(--border);
  }

  @media (max-width: ${mediaBreakpoints.mobile}) {
    div b {
      font-size: 16px;
      line-height: 16px;
    }
  }

  @media (max-width: ${mediaBreakpoints.mobile2}) {
    div small {
      flex-wrap: wrap;

      span {
        margin-bottom: 8px;
        min-width: 100%;
      }

      svg {
        margin-left: 0;
      }
    }
  }
`

export const SkipServiceField = styled.a`
  display: inline-block;
  font-size: 18px;
  line-height: 20px;
  font-weight: 500;
  color: var(--orange-bronze);

  @media (max-width: ${mediaBreakpoints.mobile}) {
    font-size: 16px;
    line-height: 16px;
  }
`

export const SkipServiceContainer = styled.div`
  position: fixed;
  bottom: 0;
  width: 100%;
  height: 152px;
  border-top: solid 1px var(--border);
  padding: 0 48px;
  display: flex;
  align-items: center;
  padding-bottom: 8px;
  background: var(--background);

  @supports (backdrop-filter: blur(10px)) {
    background: transparent;
    backdrop-filter: blur(10px) opacity(1);
  }

  @media (max-width: ${mediaBreakpoints.mobile}) {
    height: 112px;
    min-height: 112px;
    padding: 0 16px;
  }
`

export const NoResultsContainer = styled.div`
  margin-top: 32px;
  color: var(--black);

  h2 {
    font-size: 20px;
    line-height: 20px;
    font-weight: 500;
    margin: 0 0 16px;
  }

  p {
    font-size: 18px;
    line-height: 28px;
    display: block;
    margin-bottom: 32px;
  }

  @media (max-width: ${mediaBreakpoints.mobile}) {
    h2 {
      font-size: 16px;
      line-height: 16px;
    }

    p {
      font-size: 16px;
      line-height: 24px;
      margin-bottom: 24px;
    }
  }
`

export const DataColumn = styled.div<{
  hideTitleMobile?: boolean
}>`
  color: var(--black);
  font-size: 16px;
  line-height: 24px;

  b {
    display: block;
    font-size: 20px;
    line-height: 20px;
    font-weight: 500;
    margin-bottom: 16px;
  }

  small {
    font-size: 16px;
    font-weight: 400;
    display: flex;
    align-items: center;
    flex-wrap: wrap;

    svg {
      margin-right: 10px;
    }

    span {
      display: flex;
      align-items: center;
      margin-right: 15px;
    }
  }

  table {
    width: 100%;
    border-collapse: collapse;
    margin-bottom: 8px;

    tbody tr {
      font-size: 16px;
      line-height: 24px;

      td:first-child {
        width: 100%;
      }

      td:last-child {
        min-width: 150px;
        font-weight: 500;
        text-align: right;
      }

      td {
        padding-bottom: 16px;
      }
    }

    tfoot {
      tr td {
        padding-top: 16px;
        font-size: 20px;
        line-height: 20px;
        font-weight: 500;
        border-top: solid 1px var(--border);

        &:last-child {
          text-align: right;
        }
      }
    }
  }

  & + & {
    margin-top: 64px;
  }

  @media (max-width: ${mediaBreakpoints.mobile}) {
    b {
      font-size: 18px;
      line-height: 20px;
      margin-bottom: 12px;
    }

    table tfoot tr td {
      font-size: 18px;
      line-height: 20px;
    }
  }

  @media (max-width: ${mediaBreakpoints.mobile2}) {
    b {
      ${(props) => props.hideTitleMobile && `display: none;`}
    }

    table tfoot tr td {
      span {
        display: block;
        font-size: 16px;
        line-height: 24px;
        margin-top: 8px;
        font-weight: 400;
      }
    }
  }
`

export const DataRow = styled.div`
  display: flex;

  ${DataColumn} {
    flex: 1;

    & + ${DataColumn} {
      margin-top: 0;
      margin-left: 40px;
    }
  }

  & + &,
  & + ${DataColumn}, ${DataColumn} + & {
    margin-top: 64px;
  }

  @media (max-width: ${mediaBreakpoints.mobile2}) {
    flex-direction: column;

    h1 + & {
      padding-top: 8px;
    }

    ${DataColumn} + ${DataColumn} {
      margin-top: 24px;
      margin-left: 0;
    }

    & + &,
    & + ${DataColumn}, ${DataColumn} + & {
      margin-top: 48px;
    }
  }
`

export const CheckboxContainer = styled.label<{
  error?: boolean
}>`
  display: flex;
  cursor: pointer;

  ul {
    width: 20px;
    height: 20px;
    display: grid;
    place-items: center;
    position: relative;
    margin-right: 15px;

    li {
      position: absolute;
      content: '';
      width: 20px;
      height: 20px;
      border-radius: 8px;
      border: solid 2px var(${(props) => (props.error ? '--form-error' : '--border')});
      background: var(--background);
      display: grid;
      place-items: center;
      transition: border 0.2s ease, background 0.2s ease;

      &:before {
        position: absolute;
        content: '';
        width: 12px;
        height: 12px;
        background-image: url(/assets/checkbox-checkmark.svg);
        background-position: center;
        background-size: contain;
        background-repeat: no-repeat;
        z-index: 1;
        opacity: 0;
        transition: opacity 0.2s ease;
      }
    }

    input:checked + li {
      border: solid 2px var(--orange-bronze);
      background: var(--orange-bronze);

      &:before {
        opacity: 1;
      }
    }

    input[disabled] + li {
      border: solid 2px var(--form-disabled);
      background: var(--form-disabled);
    }
  }

  span {
    font-size: 16px;
    line-height: 24px;
    color: var(${(props) => (props.error ? '--form-error' : '--black')});
    font-weight: 400;
  }

  a {
    text-decoration: underline;
    font-weight: 500;
  }

  & + & {
    margin-top: 20px;
  }
`

export const ChangeDataField = styled.div`
  display: inline-block;
  font-size: 16px;
  line-height: 24px;
  font-weight: 500;
  color: var(--black);
  margin-top: 16px;
  cursor: pointer;

  & + ${DataColumn}, & + ${DataRow} {
    margin-top: 64px;
  }

  & + ${CheckboxContainer} {
    margin-top: 16px;
  }
`

export const PayButton = styled.button`
  height: 48px;
  padding: 22px 27px;
  border-radius: 37px;
  background: var(--cta-orange);
  border: none;
  color: white;
  font-size: 16px;
  line-height: 16px;
  font-weight: 700;
  margin-top: 48px;
  display: flex;
  align-items: center;
  transition: background 0.2s ease;

  &[disabled] {
    background: var(--form-disabled);
  }
  &:hover {
    background: var(--cta-orange-hover);
  }

  ${LoadingSpinnerContainer} {
    margin-right: 16px;
  }

  @media (max-width: ${mediaBreakpoints.mobile}) {
    margin-left: auto;
    margin-right: auto;
    width: 100%;
    max-width: 400px;
    justify-content: center;
  }
`

export const Divider = styled.div`
  height: 1px;
  background: var(--border);
  margin: 40px 0;
  width: 100%;

  @media (max-width: ${mediaBreakpoints.mobile}) {
    margin: 32px 0;
  }
`

export const Return404 = styled.a`
  display: inline-flex;
  align-items: center;
  color: var(--orange-bronze);
  margin-top: 48px;
  font-size: 18px;
  line-height: 24px;
  font-weight: 500;
`

export const RadioInput = styled.input`
  accent-color: var(--orange-bronze);
  margin-right: 16px;
`
