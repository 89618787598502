import styled, { createGlobalStyle } from 'styled-components'

export const mediaBreakpoints = {
  mobile: '1000px',
  mobile2: '600px',
  mobile3: '420px',
  noBreakpoint: '0px'
}

export default createGlobalStyle`
  :root {
    --background: #fff;
    --dark-background: #2F4A4C;
    --black: #324241;
    --white: #ffffff;
    --pastel-green: #CFDDC7;
    --sage: #B9B28C;
    --button-inactive: #E5E1C7;
    --orange-bronze: #BA522C;
    --cta-orange: #db502a;
    --cta-orange-hover: #ba522c;
    --form-focus: #BA522C;
    --form-error: #E0462F;
    --form-disabled: #D3D2B2;
    --border: #D3D2C6;
    --font-sans: "Aktiv Grotesk",-apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,Helvetica,Arial,sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol";
  }

  body {
    background: var(--background);
    font-family: var(--font-sans);
    font-weight: 400;
    height: 100%;
    width: 100%;
    display: flex;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    isolation: isolate;
    line-height: 1;

    #app {
      width: 100%;
    }
  }

  *,
  *::before,
  *::after {
    box-sizing: border-box;
  }

  * {
    background-color: transparent;
    border: 0;
    border: 0;
    font: inherit;
    font-size: 100%;
    margin: 0;
    padding: 0;
    vertical-align: baseline;
  }

  ol,
  ul {
    list-style: none;
  }

  blockquote,
  q {
    quotes: none;
  }

  html,
  body {
    height: 100%;
  }

  img,
  picture,
  video,
  canvas,
  svg {
    display: block;
    max-width: 100%;
  }

  input,
  button,
  textarea,
  select {
    font: inherit;
  }

  p,
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    overflow-wrap: break-word;
  }

  table {
    border-collapse: collapse;
    border-spacing: 0;
  }

  a {
    color: currentColor;
    text-decoration: none;
  }

  a,
  button {
    cursor: pointer;
  }

  @font-face {
    font-display: swap;
    font-family: "Aktiv Grotesk";
    font-style: normal;
    font-weight: 400;
    src: url("https://use.typekit.net/af/ab3e12/000000000000000077359d4f/30/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n4&v=3") format("woff2"),url("https://use.typekit.net/af/ab3e12/000000000000000077359d4f/30/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n4&v=3") format("woff"),url("https://use.typekit.net/af/ab3e12/000000000000000077359d4f/30/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n4&v=3") format("opentype");
  }

  @font-face {
    font-display: swap;
    font-family: "Aktiv Grotesk";
    font-style: italic;
    font-weight: 400;
    src: url("https://use.typekit.net/af/951aca/000000000000000077359d51/30/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i4&v=3") format("woff2"),url("https://use.typekit.net/af/951aca/000000000000000077359d51/30/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i4&v=3") format("woff"),url("https://use.typekit.net/af/951aca/000000000000000077359d51/30/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i4&v=3") format("opentype");
  }

  @font-face {
    font-display: swap;
    font-family: "Aktiv Grotesk";
    font-style: normal;
    font-weight: 700;
    src: url("https://use.typekit.net/af/a798a9/000000000000000077359d55/30/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n7&v=3") format("woff2"),url("https://use.typekit.net/af/a798a9/000000000000000077359d55/30/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n7&v=3") format("woff"),url("https://use.typekit.net/af/a798a9/000000000000000077359d55/30/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n7&v=3") format("opentype");
  }

  @font-face {
    font-display: swap;
    font-family: "Aktiv Grotesk";
    font-style: italic;
    font-weight: 700;
    src: url("https://use.typekit.net/af/8e3d9f/000000000000000077359d58/30/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i7&v=3") format("woff2"),url("https://use.typekit.net/af/8e3d9f/000000000000000077359d58/30/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i7&v=3") format("woff"),url("https://use.typekit.net/af/8e3d9f/000000000000000077359d58/30/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i7&v=3") format("opentype");
  }

  @font-face {
    font-display: swap;
    font-family: "Aktiv Grotesk";
    font-style: normal;
    font-weight: 500;
    src: url("https://use.typekit.net/af/c11a71/000000000000000077359d5e/30/l?subset_id=2&fvd=n5&v=3") format("woff2"),url("https://use.typekit.net/af/c11a71/000000000000000077359d5e/30/d?subset_id=2&fvd=n5&v=3") format("woff"),url("https://use.typekit.net/af/c11a71/000000000000000077359d5e/30/a?subset_id=2&fvd=n5&v=3") format("opentype");
  }

  @font-face {
    font-display: swap;
    font-family: "Aktiv Grotesk";
    font-style: italic;
    font-weight: 500;
    src: url("https://use.typekit.net/af/939926/000000000000000077359d5f/30/l?subset_id=2&fvd=i5&v=3") format("woff2"),url("https://use.typekit.net/af/939926/000000000000000077359d5f/30/d?subset_id=2&fvd=i5&v=3") format("woff"),url("https://use.typekit.net/af/939926/000000000000000077359d5f/30/a?subset_id=2&fvd=i5&v=3") format("opentype");
  }

  @media (max-width: ${mediaBreakpoints.mobile}) {
    body {
      max-height: 100%;
    }
  }
  
  em {
    font-style: italic;
  }
`

export const Wrapper = styled.div`
  display: flex;
  height: 100%;
  width: 100%;

  @media (max-width: ${mediaBreakpoints.mobile}) {
    flex-direction: column;
  }
`

export const Content = styled.div<{
  center?: boolean
}>`
  flex: 1;
  overflow-y: auto;

  ${({ center }) => center && `display: flex; align-items: center; `}
`
