import { FunctionComponent, h } from 'preact'
import steps from '@utils/steps'
import { useSetup } from '@components/context/setupProvider'
import {
  SidebarLegalText,
  SidebarContainer,
  SidebarList,
  SidebarListItem,
  SidebarLogo,
  SidebarMobileReturn
} from './styles'
import { useCallback } from 'preact/hooks'

const BasicFlowSidebar: FunctionComponent = () => {
  const { currentIndex, personalData, undertaker, orderId, setStep } = useSetup()

  const handleClick = useCallback(
    (index: number) => {
      if (currentIndex >= 4) return

      if ((index > 0 && !personalData) || (index > 1 && !undertaker) || index > 2) return

      setStep(steps[index])
    },
    [personalData, undertaker, currentIndex]
  )

  const hasDone = useCallback(
    (index: number) => {
      if ((index == 0 && personalData) || (index == 1 && undertaker) || (index == 2 && orderId))
        return true

      if (index == 3 && localStorage.getItem('clearAfter') != null) return true

      return false
    },
    [personalData, undertaker]
  )

  return (
    <SidebarContainer>
      <SidebarLogo src="/assets/logo.svg" />
      {currentIndex < 3 && currentIndex != 0 && (
        <SidebarMobileReturn onClick={() => setStep(steps[currentIndex - 1])}>
          <img src="/assets/chevron-left.svg" />
        </SidebarMobileReturn>
      )}
      <SidebarList>
        {steps.map((item, index) => (
          <SidebarListItem
            onClick={() => handleClick(index)}
            current={currentIndex == index}
            active={currentIndex == index || hasDone(index)}>
            <i>{hasDone(index) ? <img src="/assets/checkmark.svg" /> : index + 1}</i>
            <span>{item.name}</span>
          </SidebarListItem>
        ))}
      </SidebarList>
      <SidebarLegalText>
        © {new Date().getFullYear()} Circulum Vitae GmbH ·{' '}
        <a href="https://www.meine-erde.de/impressum" target="_blank">
          Impressum
        </a>{' '}
        ·{' '}
        <a href="https://www.meine-erde.de/datenschutz" target="_blank">
          Datenschutz
        </a>{' '}
        ·{' '}
        <a href="https://www.meine-erde.de/agb" target="_blank">
          AGB
        </a>
      </SidebarLegalText>
    </SidebarContainer>
  )
}

export default BasicFlowSidebar
