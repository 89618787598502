import { FunctionComponent, h } from 'preact'
import { Field, Formik, FormikProps } from 'formik'

import {
  ChangeDataField,
  CheckboxContainer,
  ContentPage,
  DataColumn,
  DataRow,
  InputForm,
  InputSpacer,
  PayButton
} from '@components/screen/styles'
import { usePreerdigungSetup } from '@components/context/setupProviderPreerdigung'
import { getSalutation, getBirthday } from '@utils/personalDataUtils'
import { FormikValues as PersonalData } from '@routesBasic/personalData'
import { iso2ToName } from '@utils/countries'
import { LoadingSpinner } from '@components/screen/loadingSpinner'
import { MobileFooter } from '@components/screen/mobileFooter'
import steps from '@utils/stepsPreerdigung'
import { getCookie } from '@utils/cookies'

type FormikValues = {
  newsletter: boolean
  terms: boolean
  referral: boolean
}

const Summary: FunctionComponent = () => {
  const { setStep, personalData, paymentType, setIsSubmitted, setPersonalDataErrors } =
    usePreerdigungSetup()

  if (personalData == null) return <></>

  return (
    <ContentPage>
      <Formik
        initialValues={{ newsletter: false, referral: false, terms: false }}
        validate={(values) => {
          const errors: { [key: string]: any } = {}

          if (!values.terms) errors.terms = true

          return errors
        }}
        validateOnBlur={false}
        validateOnMount={true}
        onSubmit={async (values, { setSubmitting }) => {
          setSubmitting(true)

          const ga_id = getCookie('_ga')

          const combinedClient: PersonalData &
            FormikValues & { payment_terms: 'One-time payment' | 'Installment' } & {
              ga_id: string | undefined
            } = {
            ...personalData,
            payment_terms: paymentType === 'monthly' ? 'Installment' : 'One-time payment',
            ga_id,
            ...values
          }

          let postData: {
            client: PersonalData & FormikValues
            clientId?: string
          } = {
            client: combinedClient
          }

          try {
            const result = await fetch(`${process.env.APIHOST}/preerdigung/new`, {
              method: 'POST',
              headers: {
                'Content-Type': 'application/json'
              },
              body: JSON.stringify(postData)
            })

            const data: any = await result.json()

            if (data.error) {
              let targetIndex = 2

              if (Object.keys(data.error).length > 0) {
                const firstKey = Object.keys(data.error)[0]

                switch (firstKey) {
                  case 'salutation':
                  case 'firstName':
                  case 'lastName':
                  case 'phone':
                  case 'birthday':
                  case 'street':
                  case 'postalCode':
                  case 'city':
                  case 'country':
                  case 'email':
                  case 'iban':
                    targetIndex = 1
                    setPersonalDataErrors(data.error)
                    break
                }

                setStep(steps[targetIndex])
              }

              return
            }

            setStep(steps[3])
            setIsSubmitted(true)

            window.dataLayer.push({ event: 'preerdigungConvertedPaymentType', value: paymentType })
          } catch (e) {
            console.error(e)
            setSubmitting(false)
          }
        }}>
        {({ errors, touched, isSubmitting, handleSubmit }: FormikProps<FormikValues>) => (
          <>
            <h1>Ihrer Angaben für die MEINE ERDE-Vorsorge</h1>
            <DataRow>
              <DataColumn>
                <b>Kontaktdaten</b>
                <p>
                  {`${getSalutation(personalData.salutation)} ${personalData.firstName} ${
                    personalData.lastName
                  }`}
                  <br />
                  {personalData.email}
                  <br />
                  {personalData.phone}
                  <br />
                  Geburtsdatum: {getBirthday(personalData.birthday)}
                </p>
                <ChangeDataField onClick={() => setStep(steps[1])}>Daten ändern</ChangeDataField>
              </DataColumn>
              <DataColumn hideTitleMobile={true}>
                <b>Adresse</b>
                <p>
                  {personalData.street}
                  <br />
                  {personalData.postalCode} {personalData.city}
                  <br />
                  {iso2ToName(personalData.country)}
                </p>
              </DataColumn>
            </DataRow>
            <DataColumn>
              <b>Gewählte Zahlungsmodalität</b>
              <p>
                {paymentType === 'monthly'
                  ? 'Ratenzahlung - einmalig: 297 €*, monatlich: 54 Raten à 49,50 €*'
                  : ''}
                {paymentType === 'one-time'
                  ? 'Einmalzahlung - einmalig: 2.900 €*, monatlich: -'
                  : ''}
              </p>
              <br/> 
              <p>*inklusive MwSt.</p>
            </DataColumn>
            <InputSpacer />
            <InputForm onSubmit={handleSubmit}>
              <CheckboxContainer>
                <ul>
                  <Field disabled={isSubmitting} name="newsletter" type="checkbox" />
                  <li />
                </ul>
                <span>
                  Ich möchte Neuigkeiten rund um MEINE ERDE in Form eines Newsletters erhalten.
                </span>
              </CheckboxContainer>
              <CheckboxContainer error={typeof errors.terms !== 'undefined' && touched.terms}>
                <ul>
                  <Field disabled={isSubmitting} name="terms" type="checkbox" />
                  <li />
                </ul>
                <span>
                  Ich stimme den{' '}
                  <a href="https://www.meine-erde.de/agb" target="_blank">
                    Nutzungsbedingungen
                  </a>{' '}
                  und der{' '}
                  <a href="https://www.meine-erde.de/datenschutz" target="_blank">
                    Datenschutzerklärung
                  </a>{' '}
                  zu.
                </span>
              </CheckboxContainer>
              <PayButton type="submit" disabled={isSubmitting}>
                {isSubmitting ? (
                  <>
                    <LoadingSpinner />
                    In wird geladen...
                  </>
                ) : (
                  'Vertragsentwurf anfordern'
                )}
              </PayButton>
            </InputForm>
          </>
        )}
      </Formik>
      <MobileFooter />
    </ContentPage>
  )
}

export default Summary
