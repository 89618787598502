import { FunctionalComponent } from 'preact'
import { MobileFooterContainer } from './styles'

export const MobileFooter: FunctionalComponent = () => (
  <MobileFooterContainer>
    © {new Date().getFullYear()} Circulum Vitae GmbH ·{' '}
    <a href="https://www.meine-erde.de/impressum" target="_blank">
      Impressum
    </a>{' '}
    ·{' '}
    <a href="https://www.meine-erde.de/datenschutz" target="_blank">
      Datenschutz
    </a>{' '}
    ·{' '}
    <a href="https://www.meine-erde.de/agb" target="_blank">
      AGB
    </a>
  </MobileFooterContainer>
)
