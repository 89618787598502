import { Fragment, FunctionComponent, h } from 'preact'
import { useEffect, useRef, useState } from 'preact/hooks'
import { Field, Formik, FormikProps } from 'formik'
import { z, ZodIssueBase } from 'zod'
import { friendlyFormatIBAN, isValidIBAN } from 'ibantools'

import {
  ContentPage,
  InputError,
  InputForm,
  InputItem,
  InputRow,
  InputSpacer
} from '@components/screen/styles'
import ContinueButtonCircular from '@components/screen/continueButtonCircular'
import countries from '@utils/countriesDACH'
import { mediaBreakpoints } from '@components/globalStyles'
import { usePreerdigungSetup } from '@components/context/setupProviderPreerdigung'
import steps from '@utils/stepsPreerdigung'
import { MobileFooter } from '@components/screen/mobileFooter'
import { userValues } from '../../components/context/setupProviderPreerdigung'

export type FormikValues = {
  salutation: string
  firstName: string
  lastName: string
  email: string
  phone: string
  birthday: string
  street: string
  postalCode: string
  city: string
  country: string
  state: string
  referralsource: string
  iban: string
  bic: string
  bank: string
  accountHolder: string
}

function getCorrectDateFormat(date: Date) {
  const day = date.getDate()
  const month = date.getMonth() + 1
  const year = date.getFullYear()

  return `${year}-${('0' + month).slice(-2)}-${('0' + day).slice(-2)}`
}

function getPrettyDate(date: Date) {
  const day = date.getDate()
  const month = date.getMonth() + 1
  const year = date.getFullYear()

  return `${day}.${month}.${year}`
}

const PersonalData: FunctionComponent = () => {
  const { setStep, personalData, paymentType, setPersonalData, personalDataErrors } =
    usePreerdigungSetup()

  const [showFakeDatePlaceholder, setShowFakeDatePlaceholder] = useState(false)
  const birthdayField = useRef(null)

  const [initialErrors] = useState(personalDataErrors)

  const [isMobile, setIsMobile] = useState(false)
  const [desktopDate, setDesktopDate] = useState<string | undefined>(
    personalData?.birthday ? getPrettyDate(new Date(Date.parse(personalData.birthday))) : undefined
  )

  useEffect(() => {
    const userAgent = navigator.userAgent
    if (/android/i.test(userAgent) || /iPad|iPhone|iPod/i.test(userAgent)) setIsMobile(true)
  }, [])

  const validateForm = (values: FormikValues) => {
    const Form = z.object({
      salutation: z
        .string()
        .refine((value) => value !== 'none', 'Bitte wählen Sie eine Anrede aus.'),
      firstName: z.string().nonempty('Bitte geben Sie einen Vornamen ein.'),
      lastName: z.string().nonempty('Bitte geben Sie einen Nachnamen ein.'),
      email: z
        .string()
        .nonempty('Bitte geben Sie eine E-Mail-Adresse an.')
        .email('Bitte geben Sie eine gültige E-Mail-Adresse an.')
        .regex(
          /^(([^<>()[\]\\.,;:\s@ßüäö\"]+(\.[^<>()[\]\\.,;:\s@ßüäö\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
          'Bitte geben Sie eine gültige E-Mail-Adresse ein.'
        ),
      phone: z.string().nonempty('Bitte geben Sie eine Telefonnummer an.'),
      birthday: z
        .string()
        .nonempty('Bitte geben Sie ein Geburtsdatum im Format TT.MM.JJJJ an.')
        .refine((val) => {
          const currentTime = new Date().getTime()
          const birthdate = new Date(val).getTime()
          if (!birthdate) return false

          const difference = currentTime - birthdate
          const age = Math.floor(difference / 31536000000)

          return age <= 120
        }, 'Bitte geben Sie ein gültiges Geburtsdatum im Format TT.MM.JJJJ an.')
        .refine((val) => {
          const currentTime = new Date().getTime()
          const birthdate = new Date(val).getTime()
          const difference = currentTime - birthdate
          const age = Math.floor(difference / 31536000000)

          return age >= 18
        }, 'Sie müssen mindestens 18 Jahre alt sein.'),
      street: z.string().nonempty('Bitte geben Sie eine Straße ein.'),
      postalCode: z.string().nonempty('Bitte geben Sie eine Postleitzahl ein.'),
      city: z.string().nonempty('Bitte geben Sie eine Stadt ein.'),
      country: z.string().refine((value) => value !== 'none', 'Bitte wählen Sie ein Land aus.'),
      state: z.string().refine((value) => value !== 'none', 'Bitte wählen Sie ein Bundesland aus.'),
      referralsource: z.string().optional(),
      iban: z
        .string()
        .refine(
          (val) => paymentType === 'one-time' || isValidIBAN(val.replaceAll(/\s/g, '')),
          'Bitte geben Sie eine gültige IBAN ein.'
        ),
      bic: z
        .string()
        .refine((val) => paymentType === 'one-time' || !!val, 'Bitte geben Sie eine BIC ein.'),
      bank: z
        .string()
        .refine((val) => paymentType === 'one-time' || !!val, 'Bitte geben Sie die Bank ein.'),
      accountHolder: z.string().optional()
    })

    const result = Form.safeParse(values)

    if (!result.success) {
      let errors: { [key: string]: any } = {}

      result.error.issues.forEach((issue: ZodIssueBase) => {
        if (!errors[issue.path[0]]) errors[issue.path[0]] = issue.message
      })

      return errors
    } else return {}
  }

  return (
    <ContentPage>
      <h1>Persönliche Daten</h1>
      <p>
        Für den Bestellvorgang benötigen wir folgende Daten von Ihnen oder der Person für die Sie
        die Vorsorge abschließen möchten:
      </p>
      <Formik
        initialValues={personalData ?? userValues}
        validate={validateForm}
        validateOnBlur={false}
        validateOnMount={true}
        initialErrors={initialErrors}
        onSubmit={(values) => {
          setPersonalData(values)
          setStep(steps[2])
        }}>
        {({
          values,
          errors,
          touched,
          isValid,
          submitForm,
          handleSubmit,
          setTouched,
          setFieldValue
        }: FormikProps<FormikValues>) => (
          <>
            <InputForm onSubmit={handleSubmit}>
              <InputRow>
                <InputItem
                  maxWidth="100px"
                  error={typeof errors.salutation !== 'undefined' && touched.salutation}>
                  <Field as="select" name="salutation">
                    <option value={'none'} disabled hidden>
                      Anrede
                    </option>
                    <option value="m">Herr</option>
                    <option value="f">Frau</option>
                  </Field>
                </InputItem>
                <InputItem error={typeof errors.firstName !== 'undefined' && touched.firstName}>
                  <Field name="firstName" placeholder="Vorname" />
                </InputItem>
                <InputItem error={typeof errors.lastName !== 'undefined' && touched.lastName}>
                  <Field name="lastName" placeholder="Nachname" />
                </InputItem>
                {errors.salutation && touched.salutation && (
                  <InputError>{errors.salutation}</InputError>
                )}
                {errors.firstName && touched.firstName && (
                  <InputError>{errors.firstName}</InputError>
                )}
                {errors.lastName && touched.lastName && <InputError>{errors.lastName}</InputError>}
              </InputRow>
              <InputItem error={typeof errors.email !== 'undefined' && touched.email}>
                <Field type="email" name="email" placeholder="E-Mail" />
                {errors.email && touched.email && <InputError>{errors.email}</InputError>}
              </InputItem>
              <InputItem error={typeof errors.phone !== 'undefined' && touched.phone}>
                <Field type="phone" name="phone" placeholder="Telefon" />
                {errors.phone && touched.phone && <InputError>{errors.phone}</InputError>}
              </InputItem>
              <InputItem
                error={typeof errors.birthday !== 'undefined' && touched.birthday}
                isDatePicker={true}
                hideContent={
                  /*isMobile &&*/ values.birthday.length == 0 && showFakeDatePlaceholder
                }>
                {
                  /*isMobile ? (
                  <>
                    {values.birthday.length == 0 && showFakeDatePlaceholder && (
                      <InputDatePlaceholder
                        placeholder="Geburtsdatum"
                        onFocus={() => setShowFakeDatePlaceholder(false)}
                        tabIndex={-1}
                      />
                    )}
                    <Field
                      type="date"
                      name="birthday"
                      placeholder="Geburtsdatum"
                      onFocus={() => {
                        setShowFakeDatePlaceholder(false)
                      }}
                      onBlur={() => {
                        setTouched({ ...touched, birthday: true })
                        setShowFakeDatePlaceholder(true)
                      }}
                      ref={birthdayField}
                    />
                  </>
                ) : */
                  <input
                    type="text"
                    name="birthday"
                    placeholder="Geburtsdatum"
                    maxLength={10}
                    onBlur={() => {
                      setTouched({ ...touched, birthday: true })
                    }}
                    onInput={(event: Event) => {
                      let { value } = event.target as HTMLInputElement
                      if (!value.match(/^(\d{1,2}\.?(?:\d{1,4})?){0,2}$/gm)) {
                        event.preventDefault()
                        const prevValue = desktopDate
                        setDesktopDate('')
                        setDesktopDate(prevValue)
                        return
                      }

                      value = value.replaceAll(/^(\d{2}\.)?(\d{2})([^.])/gu, '$1$2.$3')

                      setFieldValue(
                        'birthday',
                        value
                          ? getCorrectDateFormat(new Date(value.split('.').reverse().join('-')))
                          : '',
                        value.length > 8 ? true : false
                      )
                      setDesktopDate(value)
                    }}
                    value={desktopDate}
                  />
                }
                {touched.birthday && errors.birthday && <InputError>{errors.birthday}</InputError>}
              </InputItem>
              <InputSpacer />
              <InputItem error={typeof errors.street !== 'undefined' && touched.street}>
                <Field name="street" placeholder="Straße & Hausnummer" />
                {errors.street && touched.street && <InputError>{errors.street}</InputError>}
              </InputItem>
              <InputRow columnBreakpoint={mediaBreakpoints.noBreakpoint}>
                <InputItem
                  maxWidth="160px"
                  minWidth="102px"
                  error={typeof errors.postalCode !== 'undefined' && touched.postalCode}>
                  <Field name="postalCode" placeholder="PLZ" />
                </InputItem>
                <InputItem error={typeof errors.city !== 'undefined' && touched.city}>
                  <Field name="city" placeholder="Ort" />
                </InputItem>
                {errors.postalCode && touched.postalCode && (
                  <InputError>{errors.postalCode}</InputError>
                )}
                {errors.city && touched.city && <InputError>{errors.city}</InputError>}
              </InputRow>
              <InputItem error={typeof errors.country !== 'undefined' && touched.country}>
                <Field as="select" name="country">
                  <option value={'none'} disabled hidden>
                    Land
                  </option>
                  {countries.map((country) => {
                    if (country.id == -1) return <option disabled>-</option>
                    return <option value={country.alpha2}>{country.name}</option>
                  })}
                </Field>
                {errors.country && touched.country && <InputError>{errors.country}</InputError>}
              </InputItem>
              <InputItem error={typeof errors.state !== 'undefined' && touched.state}>
                <Field as="select" name="state">
                  <option value={'none'} disabled hidden>
                    Bundesland
                  </option>
                  <option value="Baden-Württemberg">Baden-Württemberg</option>
                  <option value="Bayern">Bayern</option>
                  <option value="Berlin">Berlin</option>
                  <option value="Brandenburg">Brandenburg</option>
                  <option value="Bremen">Bremen</option>
                  <option value="Hamburg">Hamburg</option>
                  <option value="Hessen">Hessen</option>
                  <option value="Mecklenburg-Vorpommern">Mecklenburg-Vorpommern</option>
                  <option value="Nordrhein-Westfalen">Nordrhein-Westfalen</option>
                  <option value="Niedersachsen">Niedersachsen</option>
                  <option value="Rheinland-Pfalz">Rheinland-Pfalz</option>
                  <option value="Saarland">Saarland</option>
                  <option value="Sachsen">Sachsen</option>
                  <option value="Sachsen-Anhalt">Sachsen-Anhalt</option>
                  <option value="Schleswig-Holstein">Schleswig-Holstein</option>
                  <option value="Thüringen">Thüringen</option>
                  <option value="Sonstiges">Sonstiges</option>
                </Field>
              </InputItem>
              <InputSpacer />
              {paymentType === 'monthly' && (
                <>
                  <InputItem error={typeof errors.iban !== 'undefined' && touched.iban}>
                    <Field
                      name="iban"
                      placeholder="IBAN"
                      onBlur={(event: Event) => {
                        let { value } = event.target as HTMLInputElement
                        const valueFormatted = friendlyFormatIBAN(value)

                        setFieldValue('iban', valueFormatted)
                      }}
                    />
                    {errors.iban && touched.iban && <InputError>{errors.iban}</InputError>}
                  </InputItem>
                  <InputItem error={typeof errors.bic !== 'undefined' && touched.bic}>
                    <Field name="bic" placeholder="BIC" />
                    {errors.bic && touched.bic && <InputError>{errors.bic}</InputError>}
                  </InputItem>
                  <InputItem error={typeof errors.bank !== 'undefined' && touched.bank}>
                    <Field name="bank" placeholder="Bank" />
                    {errors.bank && touched.bank && <InputError>{errors.bank}</InputError>}
                  </InputItem>
                  <InputItem
                    error={typeof errors.accountHolder !== 'undefined' && touched.accountHolder}>
                    <Field name="accountHolder" placeholder="Kontoinhaber (falls abweichend)" />
                    {errors.accountHolder && touched.accountHolder && (
                      <InputError>{errors.accountHolder}</InputError>
                    )}
                  </InputItem>
                  <InputSpacer />
                </>
              )}
            </InputForm>
            <MobileFooter />
            <ContinueButtonCircular onClick={() => submitForm()} active={isValid} />
          </>
        )}
      </Formik>
    </ContentPage>
  )
}

export default PersonalData
