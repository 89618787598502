import { FunctionComponent, h } from 'preact'

import { ContentPage, DataColumn, DataRow, Divider } from '@components/screen/styles'
import { useSetup } from '@components/context/setupProvider'
import { getBirthday, getSalutation } from '@utils/personalDataUtils'
import { iso2ToName } from '@utils/countries'
import { useEffect } from 'preact/hooks'
import { MobileFooter } from '@components/screen/mobileFooter'

const Confirmation: FunctionComponent = () => {
  const { personalData, undertaker, orderId } = useSetup()

  useEffect(() => {
    if (localStorage.getItem('clearAfter') == null)
      localStorage.setItem('clearAfter', (Date.now() + 1000 * 60 * 120).toString())

    gtag('event', 'conversion', {
      send_to: 'AW-10846994767/xd0WCPersbwDEM-CoLQo',
      transaction_id: orderId
    })
  }, [])

  if (personalData == null) return <></>

  return (
    <ContentPage>
      <h1>Herzlich Willkommen im MEINE ERDE Freundeskreis</h1>
      <p>
        Ihr Mitgliedsantrag und Ihre Zahlung sind gerade bei uns eingegangen. Vielen Dank.
        <br />
        <br />
        Schon in wenigen Werktagen erhalten Sie Ihre Anmeldebestätigung und Rechnung.
      </p>
      <Divider />
      <DataRow>
        <DataColumn>
          <b>Kontaktdaten</b>
          <p>
            {`${getSalutation(personalData.salutation)} ${personalData.firstName} ${
              personalData.lastName
            }`}
            <br />
            {personalData.email}
            <br />
            {personalData.phone}
            <br />
            Geburtsdatum: {getBirthday(personalData.birthday)}
          </p>
        </DataColumn>
        <DataColumn hideTitleMobile={true}>
          <b>Adresse</b>
          <p>
            {personalData.street}
            <br />
            {personalData.postalCode} {personalData.city}
            <br />
            {iso2ToName(personalData.country)}
          </p>
        </DataColumn>
      </DataRow>
      <DataColumn>
        <b>Gewähltes Partnerinstitut</b>
        {typeof undertaker !== 'string' && undertaker != null ? (
          <p>
            {undertaker.name}
            <br />
            <small>
              {undertaker.hubspotId && (
                <>
                  {undertaker.distance?.toFixed(1)} km
                  <svg
                    width="14"
                    height="18"
                    viewBox="0 0 14 18"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg">
                    <path
                      fill-rule="evenodd"
                      clip-rule="evenodd"
                      d="M7 18C7 18 14 13.0909 14 7.36364C14 5.41068 13.2625 3.53771 11.9497 2.15676C10.637 0.775809 8.85652 0 7 0C5.14348 0 3.36301 0.775809 2.05025 2.15676C0.737498 3.53771 0 5.41068 0 7.36364C0 13.0909 7 18 7 18ZM10 7C10 8.65685 8.65685 10 7 10C5.34315 10 4 8.65685 4 7C4 5.34315 5.34315 4 7 4C8.65685 4 10 5.34315 10 7Z"
                      fill="#B9B28C"
                    />
                  </svg>
                </>
              )}
              <span>
                {undertaker.hubspotId && `${undertaker.street}, `}
                {undertaker.postalCode} {undertaker.city}
              </span>
            </small>
          </p>
        ) : (
          <p>-</p>
        )}
      </DataColumn>
      <DataColumn>
        <b>Ihre Bestellung</b>
        <table>
          <tbody>
            <tr>
              <td>Ihre Mitgliedschaft für den MEINE ERDE Freundeskreis</td>
              <td>25,00 EUR</td>
            </tr>
          </tbody>
          <tfoot>
            <tr>
              <td>
                Gesamt <span>(inkl. 3,99 EUR MwSt.)</span>
              </td>
              <td>25,00 EUR</td>
            </tr>
          </tfoot>
        </table>
      </DataColumn>
      <MobileFooter />
    </ContentPage>
  )
}

export default Confirmation
