import { ComponentChildren, h, createContext } from 'preact'
import { useContext, useEffect, useMemo, useState } from 'preact/hooks'

import steps, { Step } from '@utils/stepsPreerdigung'
import { FormikValues as PersonalData } from '@routesPreerdigung/personalData'
import { PaymentType } from '@routesPreerdigung/paymentType'
import { generateOnPopState } from '@utils/navigation'

type Errors = {
  [key: string]: string
}

type SetupProps = {
  currentStep: Step | null
  currentIndex: number
  personalData: PersonalData | null
  paymentType: PaymentType

  personalDataErrors: Errors

  isSubmitted: boolean

  setStep: (step: Step) => void
  setPersonalData: (data: PersonalData) => void
  setPaymentType: (data: PaymentType) => void
  setIsSubmitted: (submitted: boolean) => void

  setPersonalDataErrors: (errors: Errors) => void
}
export const userValues = {
  salutation: 'none',
  firstName: '',
  lastName: '',
  email: '',
  phone: '',
  birthday: '',
  street: '',
  postalCode: '',
  city: '',
  country: 'none',
  state: 'none',
  referralsource: 'none',
  iban: '',
  bic: '',
  bank: '',
  accountHolder: ''
}

const SetupContext = createContext<SetupProps>({
  currentStep: steps[0],
  currentIndex: 0,
  personalData: userValues,
  paymentType: null,

  personalDataErrors: {},

  isSubmitted: false,

  setStep: (step: Step) => {},
  setPersonalData: (data: PersonalData) => {},

  setPaymentType: (data: PaymentType) => {},
  setIsSubmitted: (submitted: boolean) => {},

  setPersonalDataErrors: (errors: Errors) => {}
})

export const PreerdigungSetupProvider = ({ children }: { children: ComponentChildren }) => {
  const [step, setStep] = useState<Step | null>(null)
  const [personalData, setPersonalData] = useState<PersonalData | null>(null)
  const [paymentType, setPaymentType] = useState<PaymentType>(null)
  const [isSubmitted, setIsSubmitted] = useState(false)

  const [personalDataErrors, setPersonalDataErrors] = useState<Errors>({})

  const getIndexOfStep = (step: Step): number => {
    for (let i = 0; i < steps.length; i++) {
      if (step == steps[i]) return i
    }

    return 0
  }

  const currentIndex = useMemo(() => {
    if (step) return getIndexOfStep(step)

    return 0
  }, [step])

  useEffect(() => {
    window.onpopstate = generateOnPopState(personalData, null, setStep)

    if (step) document.title = `${step.name} - MEINE ERDE`
  }, [step])

  const changeStep = (step: Step) => {
    history.pushState(
      {
        indexPreerdigung: getIndexOfStep(step),
        flow: 'preerdigung'
      },
      ''
    )

    setStep(step)
  }

  const storePaymentType = (data: PaymentType) => {
    localStorage.setItem('paymentType', data ?? '')
    window.dataLayer.push({ event: 'paymentTypeSet', value: data })
    setPaymentType(data)
  }

  const storePersonalData = (data: PersonalData) => {
    localStorage.setItem('personalData', JSON.stringify(data))
    window.dataLayer.push({ userEmail: data.email })
    setPersonalData(data)
  }

  useEffect(() => {
    let targetStep = 0

    const clearAfter = localStorage.getItem('clearAfter')

    if (clearAfter != null && parseInt(clearAfter) < Date.now()) {
      localStorage.clear()
    }

    const paymentTypeFromLocalStorage = localStorage.getItem('paymentType') as PaymentType
    if (paymentTypeFromLocalStorage) {
      setPaymentType(paymentTypeFromLocalStorage)
      targetStep = 1
    }

    const personalDataFromLocalStorage = localStorage.getItem('personalData')
    if (personalDataFromLocalStorage != null) {
      const mergedData = { ...userValues, ...JSON.parse(personalDataFromLocalStorage) }
      setPersonalData(mergedData)
      if (paymentTypeFromLocalStorage != null) {
        targetStep = 2
      }
    }

    // overwrite payment from URL parameter
    const urlParams = new URLSearchParams(window.location.search)
    const urlPaymentType = urlParams.get('paymentType') as PaymentType | null
    if (urlPaymentType) {
      setPaymentType(urlPaymentType)
      storePaymentType(urlPaymentType)

      // if targetStep is at nothing, go to personal data
      if (targetStep === 0) {
        targetStep = 1
      }
    }

    // remove payment Type from URL parameter
    const urlResetPaymentType = urlParams.get('resetPaymentType')
    if (urlResetPaymentType === 'true') {
      setPaymentType(null)
      storePaymentType(null)
      targetStep = 0
    }

    setStep(steps[targetStep])

    for (let i = 0; i <= targetStep; i++)
      history.pushState(
        {
          indexPreerdigung: i,
          flow: 'preerdigung'
        },
        ''
      )
  }, [])

  const values: SetupProps = {
    currentStep: step,
    currentIndex,
    setStep: changeStep,

    personalData,
    personalDataErrors,

    paymentType,

    isSubmitted,
    setIsSubmitted,

    setPaymentType: storePaymentType,

    setPersonalData: storePersonalData,

    setPersonalDataErrors
  }

  return <SetupContext.Provider value={values}>{children}</SetupContext.Provider>
}

export const usePreerdigungSetup = () => useContext(SetupContext)
