type Country = {
  id: number
  alpha2: string
  alpha3: string
  name: string
}

export function iso2ToName(iso2: string): string {
  const country = countries.find((c) => c.alpha2 === iso2)
  return country ? country.name : ''
}

const countries: Country[] = [
  { id: 276, alpha2: 'de', alpha3: 'deu', name: 'Deutschland' },
  { id: 40, alpha2: 'at', alpha3: 'aut', name: 'Österreich' },
  { id: 756, alpha2: 'ch', alpha3: 'che', name: 'Schweiz' }
]

export default countries
