import { ComponentChildren, h, createContext } from 'preact'
import { useContext, useEffect, useMemo, useState } from 'preact/hooks'

import steps, { Step } from '@utils/steps'
import { FormikValues as PersonalData } from '@routesBasic/personalData'
import { Undertaker } from '@routesBasic/serviceSearch'
import { generateOnPopState } from '@utils/navigation'

type Errors = {
  [key: string]: string
}

type SetupProps = {
  currentStep: Step | null
  currentIndex: number
  personalData: PersonalData | null
  undertaker: Undertaker | string | null
  orderId: string | null
  pendingPayment: string | null

  personalDataErrors: Errors
  undertakerErrors: Errors

  setStep: (step: Step) => void
  setPersonalData: (data: PersonalData) => void
  setUndertaker: (data: Undertaker | string | null) => void
  setOrderId: (id: string | null) => void
  setPendingPayment: (id: string | null) => void

  setPersonalDataErrors: (errors: Errors) => void
  setUndertakerErrors: (errors: Errors) => void
}
export const userValues = {
  salutation: 'none',
  firstName: '',
  lastName: '',
  email: '',
  phone: '',
  birthday: '',
  street: '',
  postalCode: '',
  city: '',
  country: 'none',
  state: 'none',
  referralsource: 'none'
}

export const undertakerValues: Undertaker = {
  hubspotId: null,
  name: '',
  postalCode: '',
  city: ''
}

const SetupContext = createContext<SetupProps>({
  currentStep: steps[0],
  currentIndex: 0,
  personalData: userValues,
  undertaker: undertakerValues,
  orderId: null,
  pendingPayment: null,

  personalDataErrors: {},
  undertakerErrors: {},

  setStep: (step: Step) => {},
  setPersonalData: (data: PersonalData) => {},
  setUndertaker: (data: Undertaker | string | null) => {},
  setOrderId: (id: string | null) => {},
  setPendingPayment: (id: string | null) => {},

  setPersonalDataErrors: (errors: Errors) => {},
  setUndertakerErrors: (errors: Errors) => {}
})

export const SetupProvider = ({ children }: { children: ComponentChildren }) => {
  const [step, setStep] = useState<Step | null>(null)
  const [personalData, setPersonalData] = useState<PersonalData | null>(null)
  const [undertaker, setUndertaker] = useState<Undertaker | string | null>(null)
  const [orderId, setOrderId] = useState<string | null>(null)
  const [pendingPayment, setPendingPayment] = useState<string | null>(null)

  const [personalDataErrors, setPersonalDataErrors] = useState<Errors>({})
  const [undertakerErrors, setUndertakerErrors] = useState<Errors>({})

  const getIndexOfStep = (step: Step): number => {
    for (let i = 0; i < steps.length; i++) {
      if (step == steps[i]) return i
    }

    return 0
  }

  const currentIndex = useMemo(() => {
    if (step) return getIndexOfStep(step)

    return 0
  }, [step])

  useEffect(() => {
    window.onpopstate = generateOnPopState(personalData, setStep, null, undertaker, orderId)

    if (step) document.title = `${step.name} - MEINE ERDE`
  }, [step])

  const changeStep = (step: Step) => {
    history.pushState(
      {
        index: getIndexOfStep(step),
        flow: 'freundeskreis'
      },
      ''
    )

    setStep(step)
  }

  useEffect(() => {
    let targetStep = 0

    const clearAfter = localStorage.getItem('clearAfter')

    if (clearAfter != null && parseInt(clearAfter) < Date.now()) {
      localStorage.clear()
    }

    const personalDataFromLocalStorage = localStorage.getItem('personalData')
    if (personalDataFromLocalStorage != null) {
      const mergedData = { ...userValues, ...JSON.parse(personalDataFromLocalStorage) }
      setPersonalData(mergedData)
      targetStep = 1
    }

    const undertakerFromLocalStorage = localStorage.getItem('undertaker')
    if (undertakerFromLocalStorage != null) {
      if (undertakerFromLocalStorage === 'none') setUndertaker(undertakerFromLocalStorage)
      else {
        const mergedData = { ...undertakerValues, ...JSON.parse(undertakerFromLocalStorage) }
        setUndertaker(mergedData)
      }

      targetStep = 2
    }

    const orderIdFromLocalStorage = localStorage.getItem('orderId')
    if (orderIdFromLocalStorage != null) {
      setOrderId(orderIdFromLocalStorage)
      targetStep = 3
    }

    setStep(steps[targetStep])

    for (let i = 0; i <= targetStep; i++)
      history.pushState(
        {
          index: i,
          flow: 'freundeskreis'
        },
        ''
      )
  }, [])

  const storePersonalData = (data: PersonalData) => {
    localStorage.setItem('personalData', JSON.stringify(data))
    setPersonalData(data)
  }

  const storeUndertaker = (data: Undertaker | string | null) => {
    if (data == null) localStorage.removeItem('undertaker')

    if (typeof data === 'string') localStorage.setItem('undertaker', data)
    else localStorage.setItem('undertaker', JSON.stringify(data))

    setUndertaker(data)
  }

  const storeOrderId = (id: string | null) => {
    if (id == null) localStorage.removeItem('orderId')
    else localStorage.setItem('orderId', id)

    setOrderId(id)
  }

  const values: SetupProps = {
    currentStep: step,
    currentIndex,
    setStep: changeStep,

    personalData,
    undertaker,
    orderId,
    pendingPayment,

    personalDataErrors,
    undertakerErrors,

    setPersonalData: storePersonalData,
    setUndertaker: storeUndertaker,
    setOrderId: storeOrderId,
    setPendingPayment,

    setPersonalDataErrors,
    setUndertakerErrors
  }

  return <SetupContext.Provider value={values}>{children}</SetupContext.Provider>
}

export const useSetup = () => useContext(SetupContext)
