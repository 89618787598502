import { FormikValues as PersonalData } from '@routesBasic/personalData'
import { Undertaker } from '@routesBasic/serviceSearch'
import steps, { Step } from './steps'
import stepsPreerdigung from './stepsPreerdigung'

// kind of an ugly hack
// it produces a pop state fitting both flows, but the types are then not properly done
export const generateOnPopState =
  (
    personalData: PersonalData | null,
    setStep: ((step: Step) => void) | null,
    setStepPreerdigung: ((step: Step) => void) | null,
    undertaker: Undertaker | string | null = null,
    orderId: string | null = null
  ) =>
  (e: PopStateEvent) => {
    const { flow, index, indexPreerdigung } = e.state

    if (flow === 'freundeskreis' && index != null) {
      if (
        index == 0 ||
        (index == 1 && personalData != null) ||
        (index == 2 && undertaker != null) ||
        (index == 3 && orderId != null)
      ) {
        setStep ? setStep(steps[index]) : undefined

        e.preventDefault()
        e.stopPropagation()
      }
    }

    if (flow === 'preerdigung' && indexPreerdigung != null) {
      if (indexPreerdigung == 0 || (indexPreerdigung == 1 && personalData != null)) {
        setStepPreerdigung ? setStepPreerdigung(stepsPreerdigung[indexPreerdigung]) : undefined

        e.preventDefault()
        e.stopPropagation()
      }
    }
  }
