import { FunctionComponent, h } from 'preact'

import { ContentPage, DataColumn, DataRow, Divider } from '@components/screen/styles'
import { getBirthday, getSalutation } from '@utils/personalDataUtils'
import { iso2ToName } from '@utils/countries'
import { useEffect } from 'preact/hooks'
import { MobileFooter } from '@components/screen/mobileFooter'
import { usePreerdigungSetup } from '@components/context/setupProviderPreerdigung'

const Confirmation: FunctionComponent = () => {
  const { personalData, paymentType } = usePreerdigungSetup()

  useEffect(() => {
    if (localStorage.getItem('clearAfter') == null)
      localStorage.setItem('clearAfter', (Date.now() + 1000 * 60 * 120).toString())
  }, [])

  if (personalData == null) return <></>

  return (
    <ContentPage>
      <h1>Bestätigung</h1>
      <p>
        Wir haben Ihren Auftrag für Ihre MEINE ERDE-Vorsorge erhalten und senden Ihnen 
        alle weiteren Informationen in wenigen Augenblicken per E-Mail.
      </p>
      <Divider />
      <DataRow>
        <DataColumn>
          <b>Ihre Daten</b>
          <p>
            {`${getSalutation(personalData.salutation)} ${personalData.firstName} ${
              personalData.lastName
            }`}
            <br />
            {personalData.email}
            <br />
            {personalData.phone}
            <br />
            Geburtsdatum: {getBirthday(personalData.birthday)}
          </p>
        </DataColumn>
        <DataColumn hideTitleMobile={true}>
          <b>Adresse</b>
          <p>
            {personalData.street}
            <br />
            {personalData.postalCode} {personalData.city}
            <br />
            {iso2ToName(personalData.country)}
          </p>
        </DataColumn>
      </DataRow>
      <DataColumn>
        <b>Gewählte Zahlungsmodalität</b>
        <p>
          {paymentType === 'monthly'
          ? 'Ratenzahlung - einmalig: 297 €*, monatlich: 54 Raten à 49,50 €*'
          : ''}
          {paymentType === 'one-time'
          ? 'Einmalzahlung - einmalig: 2.900 €*, monatlich: -'
          : ''}
        </p>
        <br/> 
        <p>*inklusive MwSt.</p>
      </DataColumn>
      <MobileFooter />
    </ContentPage>
  )
}

export default Confirmation
